a{
  color: #000;
	text-decoration: none;
}

.errorMessage{
 border:1px solid #f5aca6;
 border-radius:10px;
 padding:10px;
 margin:10px 0px;
 width: 100%;
 background-color: #FFCCCC;
}
